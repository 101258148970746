import React from 'react';
import styled from 'styled-components';

import { Layout, Seo, Title, Text, Job } from '../components';
import hand from '../images/hand.png';
import prime from '../images/prime.png';
import corretores from '../images/corretores.png';
import proprietarios from '../images/proprietarios.png';
import talk7 from '../images/talk7.png';
import clickbus from '../images/clickbus.png';
import paralelos from '../images/paralelos.png';

const Highlight = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #ececec;
  padding-top: 70px;
  text-align: center;
`;

const Wrapper = styled.div`
  > ${Title} {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      margin-left: 10px;
    }

    @media (max-width: 900px) {
      font-size: 32px;
      margin-top: 58px;
    }
  }

  > ${Text} {
    text-align: center;
    margin-top: 16px;

    @media (max-width: 900px) {
      font-size: 22px;
    }
  }

  ${Highlight} {
    margin-bottom: 20px;
    margin-top: 60px;
  }
`;

const Jobs = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 84px;
  margin-left: -24px;
  margin-right: -24px;

  @media (max-width: 900px) {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Img = styled.img`
  @media (max-width: 900px) {
    display: none;
  }
`;

const jobList = [
  {
    title: 'Plataforma para gestão de locação de imóveis',
    description: 'Janeiro 2019 - QuintoAndar',
    image: prime,
    align: 'center',
    href: '/quintoandar',
    imageWidth: 439,
  },
  {
    title: 'Gestão do pós-visita para corretores',
    description: 'Maio 2018 - QuintoAndar',
    image: corretores,
    inProgress: true,
    href: '/404',
    imageWidth: 208,
  },
  {
    title: 'Cadastro de imóveis para proprietários',
    description: 'Outubro 2017 - QuintoAndar',
    image: proprietarios,
    inProgress: true,
    href: '/404',
    imageWidth: 208,
  },
  {
    title: 'Aplicativo para gestão de vendas',
    description: 'Outubro 2016 - Elo7',
    image: talk7,
    inProgress: true,
    href: '/404',
    imageWidth: 208,
  },
  {
    title: 'Aplicativo para comprar tickets de ônibus',
    description: 'Janeiro 2015 - Clickbus',
    image: clickbus,
    inProgress: true,
    href: '/404',
    imageWidth: 208,
  },
  {
    title: 'Projetos paralelos',
    description: 'De tudo um pouco',
    image: paralelos,
    align: 'center',
    inProgress: true,
    href: '/404',
    imageWidth: 439,
  },
];

const IndexPage = () => (
  <Layout>
    <Wrapper>
      <Seo title="Beatriz Figueiredo" />
      <Title size={34} weight={500}>
        Oie, eu sou a Bia! Legal te ver aqui{' '}
        <Img alt="Emoji de mão aberta" src={hand} width="38px" height="38px" />
      </Title>
      <Text size={28} color="#9B9B9B">
        Quer dar uma espiada no que andei trabalhando?
      </Text>
      <Jobs>
        {jobList.map(
          ({
            description,
            title,
            image,
            align,
            inProgress,
            href,
            imageWidth,
          }) => (
            <Job
              key={title}
              description={description}
              title={title}
              image={image}
              align={align}
              inProgress={inProgress}
              href={href}
              imageWidth={imageWidth}
            />
          )
        )}
      </Jobs>
    </Wrapper>
  </Layout>
);

export default IndexPage;
